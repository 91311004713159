import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-759d36df"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "loading Allmiddle"
};
const _hoisted_2 = {
  class: "loading_middleBox"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, item => {
    return _createElementVNode("div", {
      class: "loading_middleBox_point",
      key: item
    }, ".");
  }), 64))])]);
}