import { defineComponent } from "vue";
import { useAppStore } from "@/store/index";
const store_ = useAppStore();
export default defineComponent({
  props: {
    list: [],
    id: [],
    ImgPosition: []
  },
  data() {
    return {
      whenNull: "@assets/images/whenNull.png",
      store: store_
    };
  },
  created() {
    console.debug(this.list);
  },
  methods: {
    async lows(item) {
      this.$emit("lows", item);
    },
    toFixed2(value) {
      if (!isNaN(Number(value))) return Number(value).toFixed(2);
      return value;
    }
  }
});